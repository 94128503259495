import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './admin/components/login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AdminEgitInterceptor } from './interceptors/admin-egit.interceptor';
import { EGIT } from '../models/egit';
import { Settings } from './../models/settings';
import { HttpClientService } from './../http/http-client.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuard } from './admin/guards/auth.guard';
import { ReactiveFormsModule } from '@angular/forms';
import {  SharedModule} from './admin/components/dashboard/shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgxSpinnerModule,
    SharedModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuard,
    HttpClientService,
    { provide: HTTP_INTERCEPTORS, useClass: AdminEgitInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    const egit: Settings = {
     apiHost: 'backend.egitoilservices.com',
    //  apiHost: '192.168.1.18',
      apiEndPoint: 'api/v1',
      // apiProtocol: 'http',
     apiProtocol: 'https',
    //  apiPort: 2000,
      language: 'en',
    };
    EGIT.Settings = egit;
  }
}
