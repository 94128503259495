import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../services/auth/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [
    AuthService
  ]
})

export class LoginComponent implements OnInit {

  form: FormGroup;

  constructor(private authService: AuthService, fb: FormBuilder, private router: Router) {
    this.form = fb.group({
      "email": this.email,
      "password": this.password,
    });
  }

  ngOnInit() {
    if (this.authService.getToken()) {
      this.router.navigate(['dashboard']);
    }
  }


  email = new FormControl('', Validators.required);
  password = new FormControl('', Validators.required);

  onSubmit() {
    let body = this.form.value;

    if (!this.form.valid) {
      return this.validateAllFormFields(this.form);
    }

    this.authService.login(body).subscribe(res => {
      localStorage.setItem('user', JSON.stringify(res.data))
      this.router.navigate(['dashboard']);
    }, err => {
      return swal("Validation error !", "please enter valid pasword or email!", "error");
    });

  }

  /**
  * validate form 
  * 
  * 
  * @param formGroup 
  */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  isFieldValid(field: string) {
    return !this.form.get(field).valid && this.form.get(field).touched;
  }

  displayFieldCss(field: string) {
    return {
      'has-error': this.isFieldValid(field),
      'has-feedback': this.isFieldValid(field)
    };
  }

}
