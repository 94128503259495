import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'dashboard-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {


  public menuItems = [];

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = [
      {
        icon: 'fa fa-home',
        link: '/',
        title: 'Home',
      },
      {
        icon: 'fa fa-bitbucket',
        link: 'projects',
        title: 'Projects',
      }, {
        icon: 'fa fa-file',
        link: 'services',
        title: 'Services',
      }, {
        icon: 'fa fa-image',
        title: 'Contacts',
        link: 'contacts',
      }, {
        icon: 'fa fa-file-text',
        link: 'clients',
        title: 'Clients',
      }, {
        icon: 'fa fa-check-square',
        link: 'users',
        title: 'Users',
      },
      {
        icon: 'fa fa-info',
        link: 'about-us',
        title: 'About us',
      },
    ];
  }

  /**
   * nav to path
   * 
   * 
   * @param path 
   */
  nav(path: string) {
    this.router.navigate(['dashboard/' + path]);
  }

}
