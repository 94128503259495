import { Injectable } from '@angular/core';
import { AuthService } from './../admin/services/auth/auth.service';
import swal from 'sweetalert';
import {
    HttpInterceptor,
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';

@Injectable()
export class AdminEgitInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private spinner: NgxSpinnerService,
    ) {
    }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();

        const token: string = this.authService.getToken();
        if (token) {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
        }

        request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.spinner.hide();
                }
                if (event instanceof HttpErrorResponse) {                    
                    this.spinner.hide();
                }

                return event;
            }));

    }
}

