import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../dashboard/layouts/header/header.component';
import { FooterComponent } from '../../dashboard/layouts/footer/footer.component';
import { NavComponent } from '../../dashboard/layouts/nav/nav.component';
import { ContentComponent } from '../../dashboard/layouts/content/content.component';
import { FieldErrorDisplayComponent } from './../field-error-display/field-error-display.component';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavComponent,
    ContentComponent,
    FieldErrorDisplayComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    NavComponent,
    ContentComponent,
    FieldErrorDisplayComponent
  ]
})
export class SharedModule { }
