import { Component, OnInit } from '@angular/core';
import { AuthService } from './../../../../services/auth/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    AuthService
  ]
})

export class HeaderComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  logout() {
    this.authService.logout().subscribe(res => {
      this.authService.removeUser();
      this.router.navigate(['/login']);
    }
    );
  }
}
