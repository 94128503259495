import { Injectable } from '@angular/core';
import { HttpClientService } from './../../../../http/http-client.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClientService
  ) { }

  /**
   * get user token 
   * 
   * 
   */
  getToken() {
    if (localStorage.getItem("user")) {
      return JSON.parse(localStorage.getItem("user")).token;
    }
  }

  /**
   * user
   * 
   * 
   */
  user() {
    if (localStorage.getItem("user")) {
      const user = JSON.parse(localStorage.getItem("user"));
      return {
        username: user.name,
        email: user.email,
      }
    }
  }

  /**
   * remove user from local storage
   */
  removeUser() {
    if (localStorage.getItem("user")) {
      localStorage.removeItem("user");
    }
  }

  /**
   * logout
   * 
   * 
   * @param body 
   */
  logout() {
    return this.http.get('admin/user/logout');
  }

  /**
   * login
   * 
   * 
   * @param body 
   */
  login(body) {
    return this.http.post(body, 'admin/login');
  }

  listUsers(body) {
    return this.http.get('admin/user/get-all', body);
  }


  /**
   * delete user
   * 
   * 
   * @param body 
   */
  delete(body) {
    return this.http.post(body, 'admin/user/delete');
  }

  /**
   * create user
   * 
   * 
   * @param body 
   */
  create(body) {
    return this.http.post(body, 'admin/user/create');
  }

  /**
   * update user
   * 
   * 
   * @param body 
   */
  update(body) {
    return this.http.post(body, 'admin/user/update');
  }

  getUser(body){
    return this.http.get('admin/user/get',body);
  }

}
